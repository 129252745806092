import { createMuiTheme } from '@material-ui/core/styles'

const white = '#fff'
const black = '#000'
const primaryMain = '#78be20'
const primaryLight = '#ebf5de'
const primaryDark = '#006902'
// const secondaryMain = '#771228'
const grey = '#6f676c'
const greyLight = '#eeeef0'
const greyMedium = '#887f87'
const greyText = '#6f676c'

const imp = '!important'

const f11 = `11px ${imp}`
const f12 = `12px ${imp}`

const f14 = `14px ${imp}`
const f16 = `16px ${imp}`
const f18 = `18px ${imp}`

const f20 = `20px ${imp}`
const f23 = `20px ${imp}`
const f28 = `28px ${imp}`
const f34 = `34px ${imp}`
const f41 = `41px ${imp}`

const captionS = {
  fontSize: f11,
}
const captionM = {
  fontSize: f12,
}

const bodyS = {
  fontSize: f14,
  lineHeight: 1.5714285714,
}
const bodyM = {
  fontSize: f16,
  lineHeight: 1.5,
}
const bodyL = {
  fontSize: f18,
  lineHeight: 1.556,
}

const customSelect = {
  fontSize: `14px ${imp}`,
  borderRadius: `6px ${imp}`,
  border: `1px solid ${greyLight} ${imp}`,
  padding: `7px 10px ${imp}`,
  background: `${greyLight} ${imp}`,
  '&:focus': {
    outline: `none ${imp}`,
  },
}

const heroStyle = {
  fontSize: `28px ${imp}`,
  '@media (min-width: 680px)': {
    fontSize: `41px ${imp}`,
  },
  '@media (min-width: 1440px)': {
    fontSize: `49px ${imp}`,
  },
}

const headingS = {
  lineHeight: `1.2941176471 ${imp}`,
  fontWeight: 600,
  fontSize: f23,
  '@media (min-width: 1024px)': {
    fontSize: f28,
  },
}
const headingM = {
  lineHeight: `1.2941176471 ${imp}`,
  fontWeight: 600,
  fontSize: f28,
  '@media (min-width: 1024px)': {
    fontSize: f34,
  },
}
const headingL = {
  lineHeight: `1.2941176471 ${imp}`,
  fontWeight: 600,
  fontSize: f34,
  '@media (min-width: 1024px)': {
    fontSize: f41,
  },
}

const headingBorder = {
  content: '""',
  display: 'block',
  marginTop: `1rem ${imp}`,
  width: '4.5rem',
  height: '.25rem',
  backgroundColor: `${primaryMain} ${imp}`,
}

const headingBorderCenter = {
  marginLeft: 'auto',
  marginRight: 'auto',
}

const hpTitle = {
  ...headingM,
  textAlign: 'center',
  color: `${black} ${imp}`,
  '&:after': {
    ...headingBorder,
    ...headingBorderCenter,
  },
  '&:empty:after': {
    display: `none ${imp}`,
  },
}

const badgeNeutral = {
  ...captionM,
  padding: `3px 8px ${imp}`,
  borderRadius: `4px ${imp}`,
  color: `${greyMedium} ${imp}`,
  border: `1px solid ${greyMedium} ${imp}`,
  backgroundColor: `${greyLight} ${imp}`,
  fontWeight: `600 ${imp}`,
}

const theme = createMuiTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  themeFont: {
    main: 'LeroyMerlin',
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  typography: {
    fontFamily: "'LeroyMerlin', sans-serif",
    h1: {
      ...headingL,
    },
    h2: {
      ...headingM,
    },
    h3: {
      ...headingS,
    },
    h4: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.15,
      fontWeight: 600,
    },
    h6: {
      fontSize: 12,
      lineHeight: 1.15,
      fontWeight: 600,
    },
    body1: {
      ...bodyM,
    },
    body2: {
      ...bodyM,
    },
    caption: {
      ...bodyS,
    },
    button: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.375,
    },
  },
  palette: {
    common: {
      black: black, // Refer to Text color in Figma
      white: white,
    },
    background: {
      default: white,
      paper: white,
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
      dark: primaryDark,
      contrastText: white,
    },
    secondary: {
      main: primaryMain,
      contrastText: white,
    },
    success: {
      main: '#188803',
    },
    error: {
      main: '#c61112',
    },
    warning: {
      main: '#FF8333',
    },
    divider: black,
    primaryTransparent: {
      main: 'rgba(0, 0, 0, .5)',
      light: 'rgba(0, 0, 0, .1)',
    },
    secondaryTransparent: {
      main: 'rgba(118, 118, 118, 1)',
      light: 'rgba(118, 118, 118, .1)',
    },
    ternary: {
      main: '#0082C3',
      light: '#F1FAFF',
    },
    accent: {
      main: '#767677',
    },
  },
  sizes: {
    headerHeight: 60,
    headerHeightTransparent: 90,
    logoWidth: 110,
    logoHeight: 30,
    footerBG: 168,
    basketWidth: 360,
    basketWidthLG: 420,
  },
  extraColors: {
    grey: grey,
    greyLight: greyLight,
    greyMedium: greyMedium,
    greyText: greyText,
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '.label': {
          ...captionS,
        },
        '.select': {
          ...customSelect,
        },
        '.banner': {},
        '.header': {
          '&__cta': {
            padding: `0 ${imp}`,
          },
        },
        '#cart-menu': {
          '& .MuiPaper-root': {},
        },
        '.basket__btn': {
          '& .cart-badge': {
            right: `0px ${imp}`,
            bottom: `2px ${imp}`,
            borderColor: `${primaryMain} ${imp}`,
            color: `${white} ${imp}`,
            backgroundColor: `${primaryMain} ${imp}`,
          },
          '&.MuiButton-root': {
            borderRadius: `0 ${imp}`,
            '& .MuiButton-label': {
              '&>div': {},
              '& .basket__icon': {
                maxHeight: `30px ${imp}`,
              },
            },
          },
        },
        '.page--home': {},
        '.hero__home': {
          '& .title': {
            ...heroStyle,
          },
          '& .tagline': {
            fontSize: f12,
            '@media (min-width: 1024px)': {
              fontSize: f14,
            },
            '@media (min-width: 1440px)': {
              fontSize: f16,
            },
          },
          '& .layout': {
            alignItems: `center ${imp}`,
          },
        },
        '.daterange': {
          '&__btn': {
            fontSize: f16,
            paddingTop: `13px ${imp}`,
            paddingBottom: `13px ${imp}`,
          },
        },
        '.hero': {
          '&__title': {
            ...headingM,
            '&:after': {
              ...headingBorder,
              ...headingBorderCenter,
            },
          },
        },
        '.shipping-infos': {
          '& .MuiTypography-body2': {
            ...captionS,
          },
        },
        '.productCard': {
          '&__title': {
            ...bodyM,
            fontWeight: `600 ${imp}`,
          },
          '&__price': {
            ...bodyS,
          },
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__desc': {
            padding: `8px 0 ${imp}`,
          },
          '&__add': {
            margin: `0 ${imp}`,
          },
        },
        '.product': {
          '&__title': {
            ...headingS,
          },
          '&__price': {
            ...headingS,
          },
        },
        '.packCard': {
          '&__title': {
            fontSize: f12,
            minHeight: 37,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '@media (min-width: 1440px)': {
              fontSize: f14,
            },
          },
        },
        '.packSport': {
          '&__head': {
            borderRadius: `0 ${imp}`,
          },
          '& .pack__link': {
            color: `${primaryMain} ${imp}`,
            border: `2px solid ${primaryMain} ${imp}`,
            background: `transparent ${imp}`,
            '&:hover, &:focus': {
              background: `${primaryLight} ${imp}`,
            },
          },
          '& .pack__title': {
            '& .MuiTypography-root': {
              ...bodyL,
            },
          },
          '& .pack__desc': {
            ...bodyS,
          },
        },
        '.pack': {
          '&__title': {
            '& .MuiTypography-root': {
              ...headingS,
            },
          },
          '&__products': {
            '&-title': {
              '&:after': {
                ...headingBorder,
              },
            },
          },
        },
        '.commitment': {
          '&__title': {
            ...bodyL,
          },
        },
        '.faq': {
          '&__item': {
            marginBottom: `0 ${imp}`,
          },
          '&__question': {
            ...bodyL,
            marginBottom: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
            borderRadius: `0 ${imp}`,
            padding: `20px 48px 20px 8px ${imp}`,
            backgroundSize: '1.5rem',
            background:
              'no-repeat right 0.5rem center url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiICB2aWV3Qm94PSIwIDAgMjQgMjQiPjxwYXRoIGQ9Ik0xOS4wOSwxMWgtNlY1YTEsMSwwLDAsMC0yLDB2NmgtNmExLDEsMCwwLDAsMCwyaDZ2NmExLDEsMCwwLDAsMiwwVjEzaDZhMSwxLDAsMCwwLDAtMloiLz48L3N2Zz4=")',
            '&:hover, &:focus, &[aria-expanded=true]': {
              color: `${black} ${imp}`,
              backgroundColor: `${greyLight} ${imp}`,
            },
            '&[aria-expanded=true]': {
              border: `0 ${imp}`,
              backgroundColor: `${white} ${imp}`,
              backgroundImage:
                'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIGZpbGw9IiMyMjIwMjAiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTE3LjA5LDEzaC0xMGExLDEsMCwwLDEsMC0yaDEwYTEsMSwwLDAsMSwwLDJaIi8+PC9zdmc+")',
            },
          },
          '&__btn': {
            display: `none ${imp}`,
          },
          '&__answer': {
            ...bodyM,
            borderRadius: `0 ${imp}`,
            marginBottom: `0 ${imp}`,
            padding: `8px 8px 1rem 8px ${imp}`,
            border: `0 ${imp}`,
            borderBottom: `1px solid ${grey} ${imp}`,
          },
          '&-page': {
            '&__cat': {
              color: `${black} ${imp}`,
              '&:after': {
                ...headingBorder,
              },
            },
          },
        },
        '.product__area': {},
        '.testimonial': {},
        '.hiw, .testimonial': {
          '&__title': {
            fontSize: f20,
            fontWeight: `600 ${imp}`,
          },
        },
        '.discover': {
          '&__card, &__shadow': {
            display: 'flex',
            flexDirection: 'column-reverse',
            boxShadow: '0 2px 10px 0 rgb(0 0 0 / 20%)',
            margin: `10px ${imp}`,
            '&:hover': {
              '& .discover-product__title': {
                color: `${greyText} ${imp}`,
              },
            },
          },
        },
        '.discover-product': {
          '&__content': {
            textAlign: `left ${imp}`,
            color: `${black} ${imp}`,
            padding: `16px ${imp}`,
            position: `static ${imp}`,
            background: `transparent ${imp}`,
          },
          '&__title': {
            ...bodyM,
            color: `${black} ${imp}`,
            fontWeight: `600 ${imp}`,
          },
        },
        '.quantitySelector': {
          '&__btn': {
            borderRadius: `50% ${imp}`,
          },
        },
        '.sizes': {
          '&__btn': {},
          '&__chip': {},
        },
        '.size-card': {
          '&__soldout': {
            ...badgeNeutral,
          },
          '&__title': {
            ...bodyM,
            fontWeight: `600 ${imp}`,
          },
        },
        '.size-modal': {
          '& .MuiDialog-paper': {
            '@media (min-width: 680px)': {
              minWidth: `600px ${imp}`,
            },
          },
          '&__head': {
            backgroundColor: `transparent ${imp}`,
          },
          '&__title': {
            ...bodyS,
          },
          '&__close': {
            backgroundColor: `transparent ${imp}`,
            background:
              'transparent url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMnJlbSIgd2lkdGg9IjJyZW0iIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0iIzg4N2Y4NyI+PHBhdGggZD0iTTE3LjQxIDE2bDguOC04Ljc5YTEgMSAwIDEwLTEuNDItMS40MkwxNiAxNC41OWwtOC43OS04LjhhMSAxIDAgMDAtMS40MiAxLjQybDguOCA4Ljc5LTguOCA4Ljc5YTEgMSAwIDAwMCAxLjQyIDEgMSAwIDAwMS40MiAwbDguNzktOC44IDguNzkgOC44YTEgMSAwIDAwMS40MiAwIDEgMSAwIDAwMC0xLjQyeiIvPjwvc3ZnPg==") no-repeat',
            '&>path': {
              display: `none ${imp}`,
            },
          },
          '&__content': {
            borderColor: `${greyMedium} ${imp}`,
          },
        },
        '.section-infos': {
          '&__image': {},
          '&__content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-hiw, .section-faq, .section-testimonials': {
          '&__title': {
            ...hpTitle,
          },
        },
        '.section-faq': {
          '&__cta': {
            marginTop: `32px ${imp}`,
            marginBottom: `32px ${imp}`,
          },
        },
        '.section-heros': {
          '&>div': {
            minHeight: `auto ${imp}`,
          },
          '& .content': {
            '& h2': {
              ...hpTitle,
            },
          },
        },
        '.section-discover': {
          '&__subtitle': {
            ...hpTitle,
          },
        },
        '.section-testimonials': {},
        '.flexContent': {},
        '.footer': {
          '&__top': {},
          '&__bottom': {},
        },
        '.shop': {
          '&-menu': {
            '&__cta': {
              minWidth: `160px ${imp}`,
            },
          },
        },
        '.page': {
          '&__hero': {
            '& .MuiTypography-root': {},
          },
          '&__container': {
            '& .page-wysiwyg': {
              '& *': {},
            },
          },
        },
      },
    },
    MuiButton: {
      root: {
        boxShadow: 'none',
        borderRadius: 4,
        '&:focus': {
          boxShadow: '0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc',
        },
      },
      label: {},
      sizeSmall: {
        borderRadius: 4,
        padding: `5px 16px ${imp}`,
      },
      sizeLarge: {
        fontSize: 16,
        padding: '17px 28px',
      },
      contained: {
        padding: '13px 32px',
        '&:focus': {
          boxShadow: '0 0 0 0.125rem #ffffff, 0 0 0 0.25rem #0b96cc',
        },
        '&:hover, &:focus, &:active, &.selected': {},
        '&[disabled]': {},
      },
      containedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
      outlined: {
        borderWidth: `2px ${imp}`,
      },
      outlinedPrimary: {
        '&:hover, &:focus, &:active, &.selected': {
          backgroundColor: primaryLight,
        },
        '&.Mui-disabled': {
          background: `none ${imp}`,
          pointerEvents: `none ${imp}`,
        },
      },
      outlinedSizeSmall: {},
      containedSecondary: {
        '&:hover, &:focus, &:active, &.selected': {},
      },
    },
    MuiTab: {
      root: {
        borderRadius: `4px ${imp}`,
        padding: `5px 16px ${imp}`,
      },
    },
    MuiPaper: {
      root: {},
      rounded: {
        borderRadius: 0,
      },
    },
    MuiSelect: {
      root: {
        color: '#2933E7',
      },
      selectMenu: {
        '&:focus': {
          background: white,
        },
      },
    },
    MuiChip: {
      root: {},
      label: {},
      sizeSmall: {},
      labelSmall: {},
      colorPrimary: {},
      colorSecondary: {},
    },
  },
})

export default theme
